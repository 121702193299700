var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"profile"}},[_c('div',{staticClass:"border-bottom pb-4"},[_c('h5',[_vm._v(_vm._s(_vm.fullName))]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v("I have started my career as a trainee and prove my self and achieve all the milestone with good guidance and reach up to the project manager. In this journey, I understand all the procedure which make me a good developer, team leader, and a project manager.")])]),_c('div',{staticClass:"border-bottom pb-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-4"},[_c('h5',[_vm._v("Personal Details :")]),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"media align-items-center"},[_c('mail-icon',{staticClass:"fea icon-ex-md text-muted mr-3"}),_vm._m(0)],1),_c('div',{staticClass:"media align-items-center mt-3"},[_c('bookmark-icon',{staticClass:"fea icon-ex-md text-muted mr-3"}),_vm._m(1)],1),_c('div',{staticClass:"media align-items-center mt-3"},[_c('italic-icon',{staticClass:"fea icon-ex-md text-muted mr-3"}),_vm._m(2)],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"media align-items-center mt-3"},[_c('gift-icon',{staticClass:"fea icon-ex-md text-muted mr-3"}),_vm._m(3)],1),_c('div',{staticClass:"media align-items-center mt-3"},[_c('map-pin-icon',{staticClass:"fea icon-ex-md text-muted mr-3"}),_vm._m(4)],1),_c('div',{staticClass:"media align-items-center mt-3"},[_c('phone-icon',{staticClass:"fea icon-ex-md text-muted mr-3"}),_vm._m(5)],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-primary mb-0"},[_vm._v("Email :")]),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("kristajoseph0203@mail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-primary mb-0"},[_vm._v("Skills :")]),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("html")]),_vm._v(", "),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("css")]),_vm._v(", "),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("js")]),_vm._v(", "),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("mysql")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-primary mb-0"},[_vm._v("Language :")]),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("English")]),_vm._v(", "),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("Japanese")]),_vm._v(", "),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("Chinese")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-primary mb-0"},[_vm._v("Birthday :")]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v("2nd March, 1996")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-primary mb-0"},[_vm._v("Location :")]),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("Beijing, China")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-primary mb-0"},[_vm._v("Cell No :")]),_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v("(+12) 1254-56-4896")])])
}]

export { render, staticRenderFns }